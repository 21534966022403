import React, { ReactElement } from "react"
import ApplyContainer from "../hoc/apply"
import DefaultLayout from "../layouts/default"
import CommonPointsContainer from "../hoc/common-points"
import Data from "../data/instant-credit"
import HelmetContainer from "../hoc/helmet"
function Index({}: any): ReactElement {
  return (
    <>
      <DefaultLayout>
        <HelmetContainer pageTitle="Jetzt beantragen" />
        <ApplyContainer />
        <CommonPointsContainer data={Data} />
      </DefaultLayout>
    </>
  )
}

export default Index
